import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [sign, setSign] = useState();
  const [url, setUrl] = useState();

  const handleClear = () => {
    sign.clear();
    setUrl('');
  };

  const handleGenerate = () => {
    setUrl(sign.getTrimmedCanvas().toDataURL('image/png'));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (url) {
      console.log("Form submitted with signature:", url);
    } else {
      alert("Please save your signature before submitting the form.");
    }
  };

  return (
    <div className="container mt-4">
      <form onSubmit={handleSubmit}>
        <div className="border p-3 mb-3" style={{ width: '470px', height: 'auto' }}>
          <SignatureCanvas
            ref={data => setSign(data)}
            canvasProps={{
              width: 470,
              height: 247,
              style: { width: '100%', height: 'auto' }
            }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <button type="button" className="btn btn-secondary" onClick={handleClear}>Clear</button>
          <button type="button" className="btn btn-primary" onClick={handleGenerate}>Save</button>
        </div>
        <br />
        {url && (
          <>
            <img src={url} alt="signature" className="img-fluid mb-3" style={{ maxWidth: '100%', height: 'auto' }} />
            <input type="hidden" name="signature" value={url} />
          </>
        )}
        <button type="submit" className="btn btn-success">Submit Form</button>
      </form>
    </div>
  );
}

export default App;
